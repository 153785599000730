import { GatsbySeo } from 'gatsby-plugin-next-seo'

import CartaoDecathlon from '../components/DecathlonItau'

function DecathlonItau() {
  return (
    <>
      <GatsbySeo title="Cartão Decathlon | Decathlon" noindex nofollow />
      <CartaoDecathlon />
    </>
  )
}

export default DecathlonItau
